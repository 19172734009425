import React, { useEffect } from 'react';
import LiveLineChart from '../Charts/LiveLineChart';
import Spinner from '../Spinner';
import TabBody from '../Tabs/TabBody';
import TabContainer from '../Tabs/TabContainer';
import TabContent from '../Tabs/TabContent';
import TabHeader from '../Tabs/TabHeader';
import TabHeaderItem from '../Tabs/TabHeaderItem';
import { HumidityReading, ITHSensorProps, SensorResponse, SensorStatus, TempHumRef, TemperatureReading } from '../Interfaces/Sensors/ITHSensorCard';

var counter = 0;

const THSensorCard: React.FC<ITHSensorProps> = props => {

    const [sensorStatus, setSensorStatus] = React.useState<SensorStatus | undefined>(undefined);
    const [temperatureReadings, setTemperatureSensorReadings] = React.useState<TemperatureReading[] | undefined>(undefined);
    const [humidityReadings, setHumiditySensorReadings] = React.useState<HumidityReading[] | undefined>(undefined);
    const [isReadingsAvailable, setIsReadingsAvailable] = React.useState<boolean>(true);
    const [warningMessage, setWarningMessage] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [mac, setMac] = React.useState<string>('');
    const [thermostatTemperature, setThermostatTemperature] = React.useState<number>(0);
    const [thermostatHumidity, setThermostatHumidity] = React.useState<number>(0);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    
    const localRef = React.useRef<TempHumRef | null>(null);

    var nodeTemp = document.getElementById(props.config.mac +"_T");
    var nodeHum = document.getElementById(props.config.mac +"_H");

    const setThermostat = (event:any)=>{
      setIsProcessing(true);
      event.stopPropagation();     
      var tem = (nodeTemp as any).checked;  
      var hum = (nodeHum as any).checked;     
      props.hub.invoke("SendSetThermostatRequest", { 
                                                     nodeName: props.nodeName,
                                                     mac: props.config.mac, 
                                                     sensorType: props.config.sensorTypeViewDTO.name, 
                                                     limitTemperatureValue : thermostatTemperature,
                                                     limitHumidityValue : thermostatHumidity,
                                                     thermostatTemperatureOn: tem,
                                                     thermostatHumidityOn: hum 
                                                    }).then(()=> setIsProcessing(false));
                                                   
    }
     const setTTemperature = (value:number) => {
        var temperature = (localRef.current as unknown as TempHumRef).Temperature + value;
        var currentTemperature = sensorStatus ? parseInt(sensorStatus?.temperature) : 0;
       
        if(temperature >= currentTemperature)
          (localRef.current as unknown as TempHumRef).Temperature = (localRef.current as unknown as TempHumRef).Temperature + value;   
        setThermostatTemperature((localRef.current as unknown as TempHumRef).Temperature);     
    }
    const setTHumidity = (value:number)=>{

        var humidity = (localRef.current as unknown as TempHumRef).Humidity + value;
        var currentHumidity = sensorStatus ? parseInt(sensorStatus?.humidity) : 0;
 
        if(humidity >= currentHumidity)
          (localRef.current as unknown as TempHumRef).Humidity = (localRef.current as unknown as TempHumRef).Humidity + value;  
        setThermostatHumidity((localRef.current as unknown as TempHumRef).Humidity);                
    }
    const getSensorStatus = () => {
       if(!isProcessing) {
          props.hub.invoke("SendGetSensorStatusRequest", { producerName: "admin_ui", producerId: props.hub.connectionId, mac: props.config.mac, sensorType: props.config.sensorTypeViewDTO.name });
       }
    }
  
    useEffect(() => {
        setMac(props.config.mac);
        setIsLoading(true);
        props.hub.on("ReceiveGetSensorStatusResponse", (response: SensorResponse) => {
            if (response.mac == props.config.mac && !isProcessing) {
               if(response.sensorStatus.length > 0){
                    setIsReadingsAvailable(true);
                    var status = response.sensorStatus[response.sensorStatus.length -1];
                    if(localRef.current == null){
                        
                        var local = {
                            Temperature: parseInt(status.temperature),
                            Humidity : parseInt(status.humidity)
                         } as TempHumRef;
                        
                        localRef.current = local;
                        setThermostatTemperature(parseInt(status.temperature));
                        setThermostatHumidity(parseInt(status.humidity));    
                    }
                   
                  
                   setSensorStatus(status);
                   // setTemperatureSensorReadings(response.sensorStatus.map((item)=> {return { temperature: item.temperature, unit: item.isCelsius ? "C":"F"}}));
                   // setHumiditySensorReadings(response.sensorStatus.map((item)=> {return { humidity: item.humidity, unit: "%"}}));
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    setWarningMessage("Readings not available!!");
                    setIsReadingsAvailable(false);
                }
           }
        });
        getSensorStatus();
        const timerSensor = setInterval(() => {
            getSensorStatus();
        }, 1000);
        return () => clearInterval(timerSensor);
    }, []);

    const renderError = () => {
        return (<>
            <div className="card-body" style={{ height: '164px' }}>
                <div key={props.config.mac} className={`col-12 col-sm-${12} mb-6`}>
                    <div className="alert alert-danger" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        {sensorStatus?.errorMessage}
                    </div>
                </div>
            </div>
        </>)
    }
    const renderWarning = () => {
        return (<>
            <div className="card-body" style={{ height: '164px' }}>
                <div key={props.config.mac} className={`col-12 col-sm-${12} mb-6`}>
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div>
                      {warningMessage}
                    </div>
                   </div>
                </div>
            </div>
        </>)
    }
    const renderCard = () => {
        return (
            <>
                <div key={props.config.mac + "_Left"} className={`col-12 col-sm-${6} mb-3`}>
                    <div className="card shadow">
                        {(isLoading) && <Spinner height={'164px'} />} 
                        {(!isLoading && !sensorStatus?.isError && isReadingsAvailable) && 
                        <div className="card-body">
                          <TabContainer>
                           <TabHeader id={"myTab"}>
                             <TabHeaderItem name={"home"} active={true} icon={"bi bi-thermometer-half"}>Home</TabHeaderItem>
                             <TabHeaderItem name={"chart"}>Chart</TabHeaderItem>
                           </TabHeader>
                           <TabBody id={"myTabContent"}>
                             <TabContent itemRefName='home' active={true}>
                             <div className="row mb-3">
                               <div className={`col-6 col-sm-${6} mb-6`}>
                                    <p className="fs-2 fw-bold lh-1 mb-0 mt-1" >{sensorStatus?.temperature} {(sensorStatus?.isCelsius) ? "°C" : "F"}</p>
                                    <small className="text-muted">{sensorStatus?.time}</small>
                                    <div className="progress mt-1">
                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${sensorStatus?.temperature}%` }} aria-valuenow={parseInt(sensorStatus ? sensorStatus.temperature : "0")} aria-valuemin={0} aria-valuemax={100}>{}</div>
                                    </div>
                                </div>
                                <div className={`col-6 col-sm-${6} mb-6`}>
                                    <div className="card-text fs-2 fw-bold justify-content-center">
                                        <div className="d-flex btn-group btn-group-sm mb-2 mt-3" role="group" aria-label="Basic mixed styles example">
                                            <button type="button" className="btn btn-primary" disabled={sensorStatus?.thermostatTemperatureOn ? true : false} onClick={()=>setTTemperature(-1)}>-</button>
                                            <button type="button" className="btn btn-transparent">{sensorStatus?.thermostatTemperatureOn ? sensorStatus?.thermostatTemperatureLimit :thermostatTemperature}°C</button>
                                            <button type="button" className="btn btn-primary" disabled={sensorStatus?.thermostatTemperatureOn ? true : false} onClick={()=>setTTemperature(1)}>+</button></div>
                                        <div className="form-check form-switch" style={{ height: '10px' }}>
                                            <input className="form-check-input" onChange={(e)=>setThermostat(e)} type="checkbox" checked={sensorStatus?.thermostatTemperatureOn ? true:false} id={props.config.mac +"_T"} style={{ height: '16px' , width:'4.6em'}} />
                                            <label className="form-check-label" htmlFor={props.config.mac +"_T"}></label>
                                        </div>
                                    </div>
                                 </div>
                             </div>

                             </TabContent>
                             <TabContent itemRefName='chart'>

                               <LiveLineChart id={'popo'}/>

                             </TabContent>
                            </TabBody>
                          </TabContainer>
                        </div>
                        }
                        {(sensorStatus?.isError) && renderError()}
                        {(!isReadingsAvailable) && renderWarning()}
                        <div className="card-footer text-left bg-primary">
                            <div className="row">
                                <div className={`col-6 col-sm-${5} mb-6`}>
                                    <small className="text-white">{props.config.mac}</small>
                                </div>
                                <div className={`col-6 col-sm-${7} mb-6 text-end`}>
                                    <small className="text-white" >{sensorStatus?.temperatureDescription}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div key={props.config.mac + "_Right"} className={`col-12 col-sm-${6} mb-3`}>
                    <div className="card shadow">
                        {(isLoading) && <Spinner height={'164px'} />}
                        {(!isLoading && !sensorStatus?.isError && isReadingsAvailable) && <div className="card-body">
                            <h6 className='card-title'><i className="bi bi-droplet"></i> {sensorStatus?.humidityDescription}</h6>
                            <div className="row mb-3 mt-3">
                                <div className={`col-6 col-sm-${6} mb-6`}>
                                    <p className="fs-2 fw-bold lh-1 mb-0 mt-1">{sensorStatus?.humidity} %</p>
                                    <small className="text-muted">{sensorStatus?.time}</small>
                                    <div className="progress mt-1">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: `${sensorStatus?.humidity}%` }} aria-valuenow={parseInt(sensorStatus ? sensorStatus.humidity : "0")} aria-valuemin={0} aria-valuemax={100}>{}</div>
                                    </div>
                                </div>

                                <div className={`col-6 col-sm-${6} mb-6`}>
                                <div className="card-text fs-2 fw-bold justify-content-center">
                                        <div className="d-flex btn-group btn-group-sm mb-2 mt-3" role="group" aria-label="Basic mixed styles example">
                                            <button type="button" className="btn btn-primary" disabled={sensorStatus?.thermostatHumidityOn ? true : false} onClick={()=>setTHumidity(-1)}>-</button>
                                            <button type="button" className="btn btn-transparent">{sensorStatus?.thermostatHumidityOn ? sensorStatus?.thermostatHumidityLimit :thermostatHumidity}%</button>
                                            <button type="button" className="btn btn-primary" disabled={sensorStatus?.thermostatHumidityOn ? true : false} onClick={()=>setTHumidity(1)}>+</button></div>
                                        <div className="form-check form-switch" style={{ height: '10px' }}>
                                            <input className="form-check-input" onChange={(e)=>setThermostat(e)}  checked={sensorStatus?.thermostatHumidityOn ? true : false} type="checkbox" id={props.config.mac +"_H"} style={{ height: '16px' , width:'4.6em'}} />
                                            <label className="form-check-label" htmlFor={props.config.mac +"_H"}></label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                        {(sensorStatus?.isError) && renderError()}
                        {(!isReadingsAvailable) && renderWarning()}

                        <div className="card-footer bg-primary text-left">
                        <div className="row">
                                <div className={`col-6 col-sm-${5} mb-6`}>
                                    <small className="text-white">{props.config.mac}</small>
                                </div>
                                <div className={`col-6 col-sm-${7} mb-6 text-end`}>
                                    <small className="text-white" >{sensorStatus?.humidityDescription}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            {renderCard()}
        </>
    );
}

export default THSensorCard;





