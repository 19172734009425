import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { isTemplateSpan } from 'typescript';
import { authenticationService } from '../../Services';

interface Item{
  name:string;
  nodeId:string;

}

interface LeftMenuProps {
  items :Item[] | undefined,
  loadPage : (name:string) => void;
}
 
const LeftMenu: React.FC<LeftMenuProps> = props => {
   
if(props.items?.length == 0)
     return <></>
  
return (
    <div className="mt-3 nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        {props.items?.map((item: Item,index) => {
           return <button onClick={(e) =>props.loadPage(item.name)} key={index} className={index == 0 ? "btn btn-outline-primary btn-sm active mb-2":" btn btn-outline-primary btn-sm mb-2"} id={`v-pills-${item.nodeId}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-${item.nodeId}`} type="button" role="tab" aria-controls={`v-pills-${item.nodeId}`} aria-selected="true">{item.name}</button>
          })
        }
     </div>
)}

export default LeftMenu;


