import { AxiosInstance, AxiosResponse } from 'axios';
import { getHttpClient } from '../httpClientBaseConfig';
import { IHttpResponse } from '../Interfaces';

const getauthtoken = () => {
  let auth = localStorage.getItem('auth');
  if (auth)
    return JSON.parse(auth).auth_token;
  return null
}

class DataService {
  constructor(httpclient: AxiosInstance) {
    this._httpclient = httpclient;
  }

  private _httpclient: AxiosInstance;

  public getStatus(): Promise<IHttpResponse> {
    return this._httpclient('/admin/status', {
      method: 'GET', headers: { 'Authorization': `Bearer ${getauthtoken()}` },
      validateStatus: function (status) {
        //it resove the promise if true else exception
        return status >= 200 && status <= 400;
      },
    }).then((resp: AxiosResponse) => {
      return JSON.parse(resp.data);

    })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
}

const dataservice = new DataService(getHttpClient());

export { dataservice };