import React from 'react';
import { authenticationService, accountService } from '../Services';
import { Redirect, Link } from 'react-router-dom';

interface LoginProps {
    
}

const LoginPage: React.FC<LoginProps> = props => {

    let params = new URLSearchParams(window.location.search);
    let redirectTo = params.get('redirectTo');
    let path = redirectTo ? redirectTo.toString(): '';
    
    const [redirect, setRedirect] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isResetting, setIsResetting] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const Login = (e:any)=>{
        e.preventDefault();
        setIsLoading(true);
        authenticationService.login({username: e.target.username.value, password: e.target.password.value})
                     .then((res)=>{
                        console.log(res); 
                        setIsLoading(false);  
                        setRedirect(true);
                     }).catch((e)=>{
                        setIsLoading(false); 
                        console.log(e);
                      });
         }
    const ForgottenPassword = (e:any)=>{
        e.preventDefault();
        setIsResetting(true);
        accountService.forgotten_password({userName:e.target.email.value})
                         .then((res)=>{
                            setIsResetting(false);
                             console.log(res);   
                         }).catch((e)=>{
                            setIsResetting(false);
                            console.log(e);
                          });
             }
   
   if(redirect)
     return <Redirect to={path} />
   return( <div className="App">
                <div className="container">
                        <div className="row">
                            <div className="col-md-4 offset-md-4">
                                <div className="mt-4 p-4">
                                    <form id='1' onSubmit={(e)=> Login(e)} className="row g-3">
                                        <h4>Raspberry pi</h4>
                                        <div className="col-12">
                                            <label >Username</label>
                                            <input type="text" name="username" className="form-control" placeholder="Username"/>
                                        </div>
                                        <div className="col-12">
                                            <label>Password</label>
                                            <input type="password" name="password" className="form-control" placeholder="Password"/>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="rememberMe"/>
                                                <label className="form-check-label"> Remember me</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                           {(!isLoading) ?
                                             <div className="d-grid gap-2">
                                                 <button type="submit" className="btn btn-primary">Login</button>
                                              </div>
                                            :
                                            <div className="d-grid gap-2">
                                             <button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                 Loading...
                                            </button>
                                            </div>
                                          }
                                        </div>
                                    </form>
                                    <hr className="mt-4"/>
                                    <div className="col-12">
                                        {/* <p className="text-center mb-0">Have not account yet? <Link to={"/register"}>Signup</Link></p> */}
                                    </div>
                                    <div className="col-12">
                                        <p className="text mb-3 p-0"><a className="btn btn-link p-0" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="resetPasswordCollapse">Forgotten password ?</a></p>
                                    </div>
                                    <div className="col-12 collapse multi-collapse" id="resetPasswordCollapse">
                                        <form className="row g-3" id='2' onSubmit={(e)=> ForgottenPassword(e)}>
                                        <div className="col-12">
                                            <label htmlFor="inputEmail" className="visually-hidden">Email</label>
                                            <input type="email" name="email" className="form-control" id="inputEmail" placeholder="Email"/>
                                        </div>
                                        <div className="col-12">
                                            {(!isResetting) ?
                                             <div className="d-grid gap-2">
                                                <button type="submit" className="btn btn-primary mb-3">Reset</button>
                                              </div>
                                            : 
                                            <div className="d-grid gap-2">
                                                <button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Reset
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        </form>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
               
           
               
           
)}

export default LoginPage;