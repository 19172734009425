import axios, { AxiosInstance }  from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

//const url = 'http://localhost:8082';
const url = process.env.REACT_APP_AUTH_API_URL
const getauth = ()=>{
  let auth = localStorage.getItem('auth');
  if(auth)
   return JSON.parse(auth);
  return null
 }

const httpClient = axios.create({
    baseURL: `${url}/api/`,
    headers: {  
              'content-type': 'application/json', accept: 'application/json'
            },
    validateStatus: function (status) {
      return status >= 200 && status <= 400; 
    },
 });  

 const refreshClientLogic = async (failedRequest:any ) => {
  try {
    let auth = getauth();
    const response = await axios.post(`${url}/api/Authentication/refreshtoken`, { accessToken: auth.auth_token, refreshToken: auth.refresh_token },{ method: 'POST' } );
    if (response.status === 200) {
      localStorage.setItem('auth', JSON.stringify(response.data.result));
      httpClient.defaults.headers.common.Authorization = `Bearer ${getauth().auth_token}`;
      failedRequest.response.config.headers['Authorization'] = `Bearer ${getauth().auth_token}`;
      return Promise.resolve();
    }
    throw new Error(`Refresh failed with ${response.status} ${response.data} `);
  } catch (error) {
    if (error.response.status === 400) {
      localStorage.removeItem('auth');
      //redirect in case expired //history.push(`/`);
      Promise.reject();
    }
    throw new Error(`Refresh failed with ${error.response.status} ${error.response.data} `);
  }
};
createAuthRefreshInterceptor(httpClient, refreshClientLogic)


const authHttpClient = axios.create({
  baseURL: `${url}/api/`,
  headers: {  
            'content-type': 'application/json', accept: 'application/json'
          },
  validateStatus: function (status) {
    return status >= 200 && status <= 400; 
  },
});  
const refreshAuthLogic = async (failedRequest:any ) => {
  try {
    let auth = getauth();
    const response = await axios.post(`${url}/api/Authentication/refreshtoken`, { accessToken: auth.auth_token, refreshToken: auth.refresh_token },{ method: 'POST' } );
    if (response.status === 200) {
      localStorage.setItem('auth', JSON.stringify(response.data.result));
      authHttpClient.defaults.headers.common.Authorization = `Bearer ${getauth().auth_token}`;
      failedRequest.response.config.headers['Authorization'] = `Bearer ${getauth().auth_token}`;
      return Promise.resolve();
    }
    throw new Error(`Refresh failed with ${response.status} ${response.data} `);
  } catch (error) {
    if (error.response.status === 400) {
      localStorage.removeItem('auth');
      //redirect in case expired //history.push(`/`);
      Promise.reject();
    }
    throw new Error(`Refresh failed with ${error.response.status} ${error.response.data} `);
  }
};
createAuthRefreshInterceptor(authHttpClient, refreshAuthLogic)

const getHttpClient = () : AxiosInstance => {
    return httpClient;
}
const getAuthHttpclient = () : AxiosInstance => {
  return authHttpClient;
}
export { getHttpClient , getAuthHttpclient} 