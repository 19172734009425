import React, { useEffect } from 'react';
import Spinner from '../Spinner';
import Switch from '../Switch';
import { SensorConfiguration } from '../Interfaces/Sensors/Common';

interface IOnOffSensorProps {
    nodeName: string;
    config: SensorConfiguration;
    hub?: any;
}
interface SensorStatus {
    isError: boolean;
    errorMessage: string;
    mac: string;
    description: string;
    relays: Relay[];
    time: string ;
}
interface SensorResponse {
    sensorType: string;
    mac: string;
    sensorStatus: SensorStatus[];
}

interface Relay {
    status: boolean;
    description:string;
}

const initState = {
    isError: false,
    errorMessage: '',
    mac: '',
    description: '',
    relays: [],
    time: '',
}

const OnOffSensorCard: React.FC<IOnOffSensorProps> = props => {

    const [sensorStatus, setSensorStatus] = React.useState<SensorStatus>(initState);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [mac, setMac] = React.useState<string>('');
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [isReadingsAvailable, setIsReadingsAvailable] = React.useState<boolean>(true);
    const [warningMessage, setWarningMessage] = React.useState<string>("");
   
    // const [online, setOnline] = React.useState<boolean>(false);
    // const localTimeRef = React.useRef<string>('');
   
    const setOnOffSensor = () => {
        let sensors = sensorStatus.relays.map((item, index) => {
            var el = document.getElementById(props.config.mac + index +"_On") as HTMLInputElement;
            return {status: el.checked};
        });
       
        props.hub.invoke("SendSetOnOffSensorRequest", {
                        mac: props.config.mac,
                        nodeName: props.nodeName,
                        sensorType: props.config.sensorTypeViewDTO.name,
                        relays: sensors ,
                        });
    }
    const getSensorStatus = () => {
         if(!isProcessing)
            props.hub.invoke("SendGetSensorStatusRequest", { nodeName: props.nodeName, mac: props.config.mac, sensorType: props.config.sensorTypeViewDTO.name });
         }
    useEffect(() => {
        setMac(props.config.mac);
        props.hub.on("ReceiveGetSensorStatusResponse", (response: SensorResponse) => {
          if (response.mac == props.config.mac && !isProcessing) {
           if(response.sensorStatus.length > 0){
               var status = response.sensorStatus[response.sensorStatus.length -1];
               setSensorStatus(status);
               setIsLoading(false);
            }else {
                setIsLoading(false);
                setWarningMessage("Readings not available!!");
                setIsReadingsAvailable(false);
            }}
        });
       
     setIsLoading(true);
        getSensorStatus();
        const timerONOFFSensor = setInterval(() => {
            getSensorStatus();
        }, 5000);
        return () => clearInterval(timerONOFFSensor);
    }, []);
       
    const renderError = () => {
        return (<>
            <div className="card-body" style={{ height: '184px' }}>
                <div key={props.config.mac} className={`col-12 col-sm-${12} mb-6`}>
                    <div className="alert alert-danger" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        {sensorStatus?.errorMessage}
                    </div>
                </div>
            </div>
        </>)
    }
    const renderWarning = () => {
        return (<>
            <div className="card-body" style={{ height: '184px' }}>
                <div key={props.config.mac} className={`col-12 col-sm-${12} mb-6`}>
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div>
                      {warningMessage}
                    </div>
                   </div>
                </div>
            </div>
        </>)
    }
    const renderCard = () => {
        return (
            <>
                <div key={props.config.mac + "_Left"} className={`col-12 col-sm-6 mb-3`}>
                    <div className="card shadow">
                        {(isLoading) && <Spinner height={'184px'} />}
                        {(!isLoading && !sensorStatus?.isError && isReadingsAvailable) &&
                                                                         //164
                            <div className="card-body" style={{ height: '184px' }}>
                                <div className="container">
                                  <p className="text-center mb-0"><small className="text-muted">{`${sensorStatus?.time}`}</small></p>
                                  
                                    <div className="row">
                                      { sensorStatus.relays?.map((item, index) => {
                                         return (<div key={index} className="col col col-sm d-flex justify-content-center">    
                                                   <Switch online={true} id={props.config.mac + index} title={item?.description} icon={"bi bi-lightbulb"} isOn={item?.status ? true : false} toggle={() => {setOnOffSensor()}}/>
                                                 </div>)
                                       })
                                       }
                                    </div>
                                </div>
                            </div>
                        }
                        {(sensorStatus?.isError) && renderError()}
                        {(!isReadingsAvailable) && renderWarning()}
                        <div className="card-footer text-left bg-primary">
                            <div className="row">
                                <div className={`col-6 col-sm-${5} mb-6`}>
                                    <small className="text-white">{props.config.mac}</small>
                                </div>
                                <div className={`col-6 col-sm-${7} mb-6 text-end`}>
                                    <small className="text-white" >{sensorStatus?.description}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            {renderCard()}
        </>
    );
}

export default OnOffSensorCard;





