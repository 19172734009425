import React, { useState, useEffect } from 'react';
import {accountService} from '../Services/AccountService';

import { Link } from 'react-router-dom';

const EmailRegistrationValidationPage = () => {

  const [isLoading, setLoading]  = React.useState(false);
  const [isVerified, setIsVerified]  = React.useState(false);
  const [isError, setIsError]  = React.useState(false)

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let userid = params.get('userid');
        let token = params.get('token');
        if (userid && token){
          setLoading(true);
          accountService.validate_registration_email({userid,token}).then((resp:any)=>{
            setLoading(false);
            if(resp.ok) 
             setIsVerified(true);
             else 
              setIsError(true);
          });
        }
    },[]);

return (
<div className="App">
      <header className="App-header">
        <div style={{width:'50%'}}>
             {isLoading && <h1>Loading....</h1> }
            {!isLoading && isVerified && <>
              <h1>Your Account is been verified Successfully, please </h1>  <Link to="/auth">login</Link>
              </>
            }
            {isError && <h1>Error</h1> }
            </div>
       </header>
</div>
)}

export default EmailRegistrationValidationPage;