import  { AxiosInstance, AxiosResponse } from 'axios';
import { getAuthHttpclient } from '../httpClientBaseConfig';
import {IRegistrationRequest, IAccountService,  IHttpResponse, IForgottenPasswordRequest,IResetPasswordRequest, IValidateRegistrationEmail } from '../Interfaces';

class AccountService implements IAccountService  {
  constructor(httpclient: AxiosInstance){
    this._httpclient = httpclient;
  }
  private _httpclient : AxiosInstance;
 
  public forgotten_password(email: IForgottenPasswordRequest): Promise<IHttpResponse> {
    return this._httpclient('/account/forgotpassword', { method: 'POST', data: email})
        .then((resp: AxiosResponse) => {
            return JSON.parse(resp.data);
        }).catch((e)=>{
             throw e
        });
  }
  public reset_password(body: IResetPasswordRequest): Promise<IHttpResponse> {
    return this._httpclient('/account/resetpassword', { method: 'POST', data: body})
        .then((resp: AxiosResponse) => {
            return JSON.parse(resp.data);
        }).catch((e)=>{
             throw e
        });
  }
  public register(user: IRegistrationRequest): Promise<IHttpResponse> {
    return this._httpclient('/account/register', { method: 'POST', data: user  })
    .then((resp: AxiosResponse) => {
            return JSON.parse(resp.data);
        }).catch((e)=>{
             throw e
        });
  }
  public validate_registration_email(body: IValidateRegistrationEmail): Promise<IHttpResponse> {
    return this._httpclient('/account/emailvalidation', { method: 'POST', data: body  })
    .then((resp: AxiosResponse) => {
            return JSON.parse(resp.data);
        }).catch((e)=>{
             throw e
        });
  } 
}
const accountService = new AccountService(getAuthHttpclient());

export {accountService};