import React from 'react';


interface PageNotFoundPage{
    aggiunto?:string;
}

const PageNotFoundPage: React.FC<PageNotFoundPage> = props => {

    return( <div className="App">
                
                <div style={{width:'300px', margin:'auto'}}>
                  <p>PAGE NOT FOUND</p>
                </div>
            </div>
)}

export default PageNotFoundPage;