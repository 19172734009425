import TerminalPage from "./Pages/TerminalPage";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import HomePage from "./Pages/HomePage";
import EmailRegistrationValidation from "./Pages/EmailRegistrationValidationPage";
import EmailResetPassword from "./Pages/EmailChangePasswordPage";
import PageNotFoundPage from "./Pages/PageNotFoundPage";
import SettingsPage from "./Pages/SettingsPage";

export const Routes = [
    //Public Routes
    { path: "/login", exact:false, auth:false, component: LoginPage},
    //{ path: "/register", exact:false, auth: false, component : RegisterPage},
    { path: "/emailvalidation", exact:false, auth:false, component: EmailRegistrationValidation},
    { path: "/resetpassword", exact:false, auth:false, component : EmailResetPassword},
    //Authorized Routes
    { path: "/" , exact:true, auth: true, component : HomePage},
    { path: "/terminal" , exact:false, auth: true, component : TerminalPage},
    { path: "/settings", exact:false, auth:true, component: SettingsPage},
    { path:'*'  ,exact:true, auth:true,  component: PageNotFoundPage},
]
