import React, { useState, useEffect } from 'react';


interface LeftMenuTabWrapperProps {
  
}
 
const LeftMenuTabWrapper: React.FC<LeftMenuTabWrapperProps> = props => {
   
  
return (
  <div className="tab-content" id="v-pills-tabContent">
    {props.children}
  </div>
)}

export default LeftMenuTabWrapper;


