import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { dataservice, authenticationService } from '../Services';
import Nav from '../Components/Nav';
import LeftMenu from '../Components/LeftMenu/LeftMenu';
import LeftMenuTabWrapper from '../Components/LeftMenu/LeftMenuTabWrapper';
import LeftMenuTab from '../Components/LeftMenu/LeftMenuTab';
import Spinner from '../Components/Spinner';
import CpuInfo from '../Components/Nodes/ClusterInfo';
import RowInfo from '../Components/Nodes/RowInfo';

interface SettingsProps {
   hub?:any;
}
interface Setting {
   description: string;
   key: string;
   value: string;   
}
interface Node{
   description: string;
   name: string;
   nodeId: number;
   settings: Setting[];
}
interface Item {
   consumerId: string;
   consumerName: string;
   nodeName: string;
   producerId: string;
   producerName: string;
   nodes: Node[];
}
interface State {
   items: Item[];   
}
const initState : State = {
  items: [],
}
const SettingsPage: React.FC<SettingsProps> = props => {

   const [data, setData] = React.useState<State>(initState);
   const latestData = React.useRef<any>(null);
  
   latestData.current = data.items;

   useEffect(() => {
      const getSettingsByNodeName = (nodeName: string = '') => {
        // props.hub.invoke("SendGetSettingsByNodeNameRequest", { producerName:"admin_ui",producerId :  props.hub.connectionId,nodeName:nodeName })
        return;
      }
      props.hub.on("ReceiveGetSettingsByNodeNameResponse", (response: any) => {
        
         let index = latestData.current.findIndex((item :Item)=> item.nodeName == response.nodeName);
         
         let newValues = [];
         if(index == -1)
            newValues = [...latestData.current, response];
         else {
            newValues = [
               ...latestData.current.slice(0, index),
               response,
               ...latestData.current.slice(index + 1)
             ]
         }   
         setData({ items: newValues })
      });
      getSettingsByNodeName();
   }, []);

   return (<div className="App">
            <Nav></Nav>
            {(data.items.length == 0) 
            ? <Spinner/> :
            <div className="container">
               <div className="row">
                  <div className="col">
                     <LeftMenu items={data.items.map((item :any) => item.consumerName)} loadPage ={()=>true}/>
                  </div>
                  <div className="col-12 col-sm-8">
                     <LeftMenuTabWrapper>
                     {/* {data.items.map((node:Item,index:number) => {
                           return (<LeftMenuTab name={node.consumerName} active={index == 0? true:false}>
                                 <CpuInfo  temperature={node?.cpu.temperature} celsius={node?.cpu.celsius} title={node?.cpu.title} lastUpdate={node?.cpu.lastUpdate}/>
                                 { node.items.map((item:any) => {
                                    return <RowInfo data={item.items} />
                                 })}
                                 </LeftMenuTab>)
                        })} */}
                     </LeftMenuTabWrapper>
                  </div>
                  <div className="col"></div>
               </div>
            </div>
            }
         </div>
   )
}

export default SettingsPage;