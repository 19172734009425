import React from 'react';

interface ISpinnerProps {
    height?: string;
}

const Spinner: React.FC<ISpinnerProps> = props => {
    return (<div style={{ height: (props.height) ? props.height :'100vh' }} className="align-items-center d-flex justify-content-center">
         <div className="spinner-border text-primary" role="status">
             <span className="visually-hidden">Loading...</span>
         </div></div>)
}

export default Spinner;





