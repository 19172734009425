import React from 'react';

interface ITabHeaderItemProps {
 name: string;
 active?: boolean;
 icon?:any;
}

const TabHeaderItem : React.FC<ITabHeaderItemProps> = props => {

    const name = props.name.toLowerCase();
    const active = props.active == true ? "active" : ""; 
    return (
       <>
        <li className="nav-item" role="presentation">
            <button className={`nav-link ${active}`} id={`${name}-tab`} data-bs-toggle="tab" data-bs-target={`#${name}`} type="button" role="tab" aria-controls={`${name}`} aria-selected="true"><i className={props.icon}></i>{props.children}</button>
        </li>
       </>  
    );
}

export default TabHeaderItem;





