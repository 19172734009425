import React from 'react';

interface ITabHeaderProps {
  id: string;
}

const TabHeader : React.FC<ITabHeaderProps> = props => {
    return (
       <>
        <ul className="nav nav-tabs" id={props.id} role="tablist">
           {props.children}
        </ul>
       </>  
    );
}

export default TabHeader;





