import React, { useEffect } from 'react';
import Spinner from '../Spinner';

export interface ClusterNode {
    temperature : string;
    celsius : boolean;
    title : string;
    lastUpdate : string;
 }
 
 export interface ClusterProps {
  nodes: ClusterNode[] | undefined; 
  loadClusterData : ()=>void;
 }

const ClusterInfo : React.FC<ClusterProps> = props => {

    const nodes = props.nodes;

    const RenderTemperature = (temperature: string, celcius=true) => {
        return <p className="card-text fs-2 fw-bold">{`${temperature} ${(celcius) ? '°C' : '°F'}`}</p>;
    }

    useEffect(() => {
        const timerCluster = setInterval(() => {
            props.loadClusterData();
        }, 1000);
        return () => clearInterval(timerCluster);
    },[]);


    return (
        <div className="App">
            <div className="row mb-3 mt-3">
                <div className="col-12 col-sm-12">
                    <div className="card shadow">
                      {(nodes == undefined) ? <Spinner height={"167px"} /> :
                        <div className="card-body">
                             <div className="row">
                               {nodes?.map((item: ClusterNode,index:number) => {
                                return (<div key={index} className="col">
                                            <div className="row">
                                            <div className="col">
                                                <h6 className="card-title"><i className="bi bi-thermometer-half"></i>{item.title}</h6>
                                                <div className="progress">
                                                  <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${item.temperature}%` }} aria-valuenow={parseInt(item.temperature ? item.temperature : "0")} aria-valuemin={0} aria-valuemax={100}>{} {}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {RenderTemperature(item.temperature)}
                                            </div>
                                            </div>
                                        </div>)
                                  })}
                             </div>
                        </div>
                       }
                        <div className="card-footer bg-primary">
                            <small className="text-white">{"Swarm Cluster"}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClusterInfo;


