import React, { useEffect } from 'react';

interface ISwitchProps {
    labelTop?: string;
    labelBottom?: string;
    id: string;
    isOn: boolean;
    title: string;
    icon: string;
    toggle: () => any;
    online:boolean;
}

const Switch: React.FC<ISwitchProps> = props => {

    const changeValue = (id: string) => {
        var el = document.getElementById(id) as HTMLInputElement
        (el as any).tempCheck = !el.checked;
        props.toggle();
    }

    return (
        <div className='mt-1'>
            <h6 className='card-title text-truncate' style={{ maxWidth: 94 }}><i className={props.icon} ></i> {props.title}</h6>
            <div className="card" style={{ width: 'min-content' }}>
                <div className="card-body">

                    <div className="btn-group-vertical" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" onChange={() => { changeValue(`${props.id}_Off`) }} className="btn-check" name={`${props.id}_Name`} id={`${props.id}_Off`} autoComplete="off" checked={(!props.isOn) ? true : false} />
                        <label className="btn btn-outline-danger " htmlFor={`${props.id}_Off`}>{props.labelTop ? props.labelTop : "Off"}</label>

                        <input type="radio" onChange={() => changeValue(`${props.id}_On`)} className="btn-check" name={`${props.id}_Name`} id={`${props.id}_On`} autoComplete="off" checked={props.isOn ? true : false} />
                        <label className="btn btn-outline-success " htmlFor={`${props.id}_On`}>{props.labelBottom ? props.labelBottom : "On"}</label>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Switch;





