import  { AxiosInstance, AxiosResponse } from 'axios';
import { getHttpClient } from '../httpClientBaseConfig';
import { IHttpResponse } from '../Interfaces';

const getauthtoken = ()=>{
    let auth = localStorage.getItem('auth');
    if(auth)
     return JSON.parse(auth).auth_token;
    return null
   }

class CommandService {
    constructor(httpclient: AxiosInstance){
        this._httpclient = httpclient;
      }

private _httpclient : AxiosInstance;

public check(): Promise<any> { 
  return this._httpclient('tokenvalidation/check',{ method: 'GET', headers:{'Authorization': `Bearer ${getauthtoken()}`},
                                    validateStatus: function (status) {
                                    //it resove the promise if true else exception
                                    return status >= 200 && status <= 400; 
                                 },
                       }).then((resp: AxiosResponse) => {
                             return resp;
                       })
                       .catch((error)=>{
                         console.log(error);    
                         throw error;
                       });
 };
}

const commandService = new CommandService(getHttpClient());

export {commandService};