import * as signalR from "@microsoft/signalr";
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '../AppConfig';
import { commandService } from '../Services/CommandService';
import { AuthRoute } from './AuthRoute';
import Spinner from "./Spinner";


const AuthApp = () => {
    const [connection, setConnection] = React.useState<any>(null);

    const getauthtoken = () => {
        let auth = localStorage.getItem('auth');
        if (auth)
            return JSON.parse(auth).auth_token;
        return null
    }
    //http://localhost:58838/
    //http://rasp.traibuls.com/
    //http://rasp.traibuls.com/ServerHub
    const url = process.env.REACT_APP_HUB_API_URL
    
    useEffect(() => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${url}/ServerHub`,
                {
                    transport: signalR.HttpTransportType.LongPolling,
                    accessTokenFactory: () => {
                        return commandService.check().then(response => {
                            return getauthtoken();
                        })
                    }
                })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        async function start() {
            try {
                await hubConnection.start()
                    .catch((err) => {
                    })
                    .then(a => {
                        if (hubConnection.connectionId) {
                            setConnection(hubConnection);
                            //hubConnection.invoke("getSystemStatus");
                        }
                    });
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };
        // props.hub.onclose(async () => {
        //   console.log("On Close Called...");
        //   await start();
        // });
        // Start the connection.
        start();
    }, []);

    if (connection == null)
      return <Spinner/>

    return (
        <>
            <Switch>
                {Routes.map((route: any, index: number) => {
                    if (route.auth)
                        return <AuthRoute hub={connection} exact={route.exact} key={index} path={route.path} component={route.component} />
                })}
            </Switch>
        </>
    );
}

export default AuthApp;





