import React, { createContext } from 'react';
import logo from './logo.svg';
import './Style/App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {AuthRoute} from './Components/AuthRoute';
import  {Routes}  from './AppConfig';
import AuthApp from './Components/AuthApp';

const App = () => {
  return (
    <div className="App">
          <Router>
             <Switch>
                {Routes.map((route:any, index:number) =>{
                    if(!route.auth) 
                        return <Route exact={false} key={index} path={route.path} component={route.component} />
                 })}
                 <AuthRoute exact={false} key={"auth"} path={'/'} component={AuthApp}/> 
                </Switch>
          </Router>
    </div>
  );
}

export default App;
