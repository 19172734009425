import React from 'react';

interface ITabBodyProps {
    id:string;
}

const TabBody : React.FC<ITabBodyProps> = props => {
    return (<>
         <div className="tab-content" id={props.id}>
            {props.children}
        </div>
    </>  
    );
}

export default TabBody;





