import React from 'react';

interface ITabContentProps {
  itemRefName: string;
  active?: boolean;
}

const TabContent : React.FC<ITabContentProps> = props => {

    const name = props.itemRefName.toLowerCase();
    const active = props.active == true ? "active" : ""; 
    return (<>
             <div style={{minHeight: '90px'}} className={`tab-pane fade show ${active}`} id={name} role="tabpanel" aria-labelledby={`${name}-tab`}>
                {props.children}       
            </div>
           </>  
    );
}

export default TabContent;





