import  { AxiosInstance, AxiosResponse } from 'axios';
import { getAuthHttpclient } from '../httpClientBaseConfig';
import { IAuthenticationService, ILoginRequest, IHttpResponse } from '../Interfaces';

class AuthenticationService  implements IAuthenticationService  {
  constructor(httpclient: AxiosInstance){
    this._httpclient = httpclient;
  }
  private _httpclient : AxiosInstance;
 
  public login(credentials : ILoginRequest): Promise<IHttpResponse> { 
       return this._httpclient('/authentication/login',{ method: 'POST', data: credentials, 
                                        validateStatus: function (status) {
                                         //it resove the promise if true else exception
                                         return status >= 200 && status <= 400; 
                                      },
                            }).then((resp: AxiosResponse) => {
                                  return JSON.parse(resp.data);
                            }).then((data: IHttpResponse) => {
                               if(data.ok)
                                  localStorage.setItem('auth', JSON.stringify(data.result));
                              return data;
                            })
                            .catch((error)=>{
                              console.log(error);    
                              throw error;
                            });
  };
  public logout(){
    localStorage.removeItem('auth');
  }
}
const authenticationService = new AuthenticationService(getAuthHttpclient());

export {authenticationService};