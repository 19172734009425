import React, { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";
import { commandService } from '../Services/CommandService';
import Nav from '../Components/Nav';
import SystemStatus from '../Components/SystemStatus';
import Alert from '../Components/Alert';


interface TerminalProps {
  hub?:any;
}

const TerminalPage: React.FC<TerminalProps> = props => {

  const [connection, setConnection] = React.useState<any>(null);
  const [commandList, setMessageValue] = React.useState<any>([]);
  const latestMessages = React.useRef<any>(null);
  const commandsEndRef = useRef<HTMLInputElement>(null);
  const [commandValue, setCommandValue] = React.useState<any>('');
  const [serversAdminConnected, setServersAdminConnected] = React.useState<number>(0);
  const [alert, setAlert] = React.useState<any>(true);

  latestMessages.current = commandList;

  const getauthtoken = () => {
    let auth = localStorage.getItem('auth');
    if (auth)
      return JSON.parse(auth).auth_token;
    return null
  }
  //http://localhost:58838/
  //http://rasp.traibuls.com/api/
   useEffect(() => {

    // props.hub.invoke("SendSystemStatusRequestUpdates");

    // props.hub.on("ReceiveSystemStatusResponse", (response: any) => {
    //   updateTerminal(<SystemStatus count={response.count} details={response.details} />);
    // });
    // props.hub.on("ReceiveSystemStatusResponseUpdates", (response: any) => {
    //   var serversAdminConnected = response.details?.service_admin.length;
    //   if (serversAdminConnected)
    //     setServersAdminConnected(serversAdminConnected);
    //   else setServersAdminConnected(0);
    // });
    // props.hub.on("ReceiveResetServiceConnectionCommandResponse", (response: any) => {
    //   if (response.ok) {
    //     updateTerminal(<div className="text-success text-break"><strong>{response.sender}:</strong> {response.result}</div>);
    //   } else {
    //     updateTerminal(<div className="text-danger text-break"><strong>{response.sender}:</strong> {response.result}</div>);
    //   }
    // });
    props.hub.on("ReceiveCommandResponse", (response: any) => {
       updateTerminal(<div className="text-danger text-break"><strong>{response.sender}:</strong> {response.result}</div>);
    });
  }, []);

  useEffect(() => {
    scrollToBottom()
  }, [commandList]);

  const updateTerminal = (item: any) => {
    const updatedChat = [...latestMessages.current]
    updatedChat.push(item);
    setMessageValue(updatedChat);
  }

  const SendCommand = async (e: any) => {
    e.preventDefault();
    setCommandValue('');
    if (e.target.command.value.trim().length > 0) {
      if (e.target.command.value.trim().toUpperCase() !== "CLEAR") {
        updateTerminal(<div className="text-primary"><strong>[Command]: </strong>{e.target.command.value}</div>);
        props.hub.invoke("SendCommand", { caller: "admin_ui", execute: e.target.command.value })
      } else {
        setMessageValue([]);
      }
    }
  }
  const scrollToBottom = () => {
    commandsEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <Nav></Nav>
      <div className="container">
        {/* {alert && <Alert/>} */}
        <div className="row">
          <div className="col"></div>
          <div className="col-12 col-sm-8">
            <div className="row mb-3 mt-3">
              <div className="col-sm-12">

                <div className="card">
                  <div className="card-header text-white bg-primary">

                    <div className="row">
                      <div className="col"> <i className="bi bi-terminal"></i> Terminal</div>
                      <div className="col text-end"><i className="bi bi-hdd-network"></i> Live Services <span className="badge bg-danger">{serversAdminConnected}</span> </div>
                    </div>

                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      <form className="row g-2" id='2' onSubmit={(e) => SendCommand(e)}>
                        <div className="col-12">
                          <input type="command" value={commandValue} onChange={(e => setCommandValue(e.target.value))} className="form-control" id="command" placeholder="Insert a command" />
                        </div>
                        <div className="col-auto">
                          <button type="submit" className="visually-hidden">Send</button>
                        </div>
                      </form>
                    </h5>
                    <div className="card-text">
                      <div style={{ height: '60vh', overflow: 'auto', overflowX: 'hidden' }}>
                        {commandList.map((item: any, index: any) => {
                          return <span key={(index)}>{item} </span>
                        })
                        }
                        <div ref={commandsEndRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>
   </>
  )
}

export default TerminalPage;