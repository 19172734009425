import React, { useState, useEffect } from 'react';


interface LeftMenuTabProps {
  name:string;
  active?:boolean;
  nodeId:string;
}
 
const LeftMenuTab: React.FC<LeftMenuTabProps> = props => {
   
  
return (
  <div className={`tab-pane fade ${props.active ? "show active" : ""}`} id={`v-pills-${props.nodeId}`} role="tabpanel" aria-labelledby={`v-pills-${props.nodeId}-tab`}>
    {props.children}
  </div>
)}

export default LeftMenuTab;


