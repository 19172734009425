import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { authenticationService } from '../Services';

interface NavProps {
    hub?: any;
 }

 const Nav : React.FC<NavProps> = props => {
    const [redirect, setRedirect] =  React.useState(false);

    const getusername = () => {
        let auth = localStorage.getItem('auth');
        if (auth)
          return JSON.parse(auth).username;
        return null
      }
      
    const Logout = ()=> {
        props.hub.stop()
             .then(() => {
                authenticationService.logout();
                setRedirect(true);
               })
        };
        

if(redirect)
  return <Redirect to={'/'} />
return (
    <nav className="navbar navbar-expand-lg navbar navbar-dark bg-primary">
    <div className="container-fluid">
            <Link className="navbar-brand" to={"/"}>Traibuls</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item">
                       <Link className="nav-link" aria-current="page" to={'/'}><i className="bi bi-clipboard-data"></i> Monitor</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to={'/settings'}><i className="bi bi-gear"></i> Settings</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to={'/terminal'}><i className="bi bi-terminal"></i> Terminal</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to={'/Notifications'}>
                            <i className="bi bi-card-text"></i> Notifications <span className="badge bg-secondary">4</span>
                        </Link>
                     </li>
                </ul>
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page"><i className="bi bi-person-circle"></i> {getusername()}</a>
                     </li>
                  
                    <li className="nav-item">
                        <a className="nav-link" onClick={(e)=>{e.preventDefault();  Logout()}} href={"#"}><i className="bi bi-box-arrow-right"></i> Logout</a>
                    </li>
                </ul>
            </div>
    </div>
  </nav>
    
)}

export default Nav;