import React from 'react';

interface ITabContainerProps {



}

const TabContainer : React.FC<ITabContainerProps> = props => {
   
    return (
         <div className='tab-container'>
           {props.children}
         </div>  
    );
}

export default TabContainer;





