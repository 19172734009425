import React, { useEffect } from 'react';
//import * as d3 from 'd3';

interface ILiveLineChartProps {
 id:string;    
}

const LiveLineChart: React.FC<ILiveLineChartProps> = props => {

  
    //const [sensorStatus, setSensorStatus] = React.useState<SensorStatus | undefined>(undefined);
  

    useEffect(() => {
    //     var svg = d3.select("#"+ props.id)
    //     .append("svg")
    //       .attr("width", 1000)
    //       .attr("height", 100)
      
    //   // Create the scale
    //   var y = d3.scaleLinear()
    //       .domain([0, 50])         // This is what is written on the Axis: from 0 to 100
    //       .range([290, 10]);         // Note it is reversed
      
    //   // Draw the axis
    //   svg
    //     .append("g")
    //     .attr("transform", "translate(50,0)")      // This controls the vertical position of the Axis
    //     .call(d3.axisLeft(y));
  

        

        //return () => clearInterval(camTimerSensor);
    }, []);

    return (
        <div id={props.id}> </div>
    );
}

export default LiveLineChart;





