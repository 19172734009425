import React, { useEffect } from 'react';
import Nav from '../Components/Nav';
import LeftMenu from '../Components/LeftMenu/LeftMenu';
import LeftMenuTabWrapper from '../Components/LeftMenu/LeftMenuTabWrapper';
import LeftMenuTab from '../Components/LeftMenu/LeftMenuTab';
import Spinner from '../Components/Spinner';
import ClusterInfo, { ClusterNode } from '../Components/Nodes/ClusterInfo';
import THSensorCard from '../Components/Sensors/THSensorCard';
import CAMSensorCard from '../Components/Sensors/CAMSensorCard';
import OnOffSensorCard from '../Components/Sensors/OnOffSensorCard';
import { SensorConfiguration } from '../Components/Interfaces/Sensors/Common';
import { ConfigurationNodeReponse, NodeInfo, Nodes } from '../Components/Interfaces/Sensors/Node';
//props
interface HomeProps {
   hub?: any;
}

const HomePage: React.FC<HomeProps> = props => {
   
   const [nodes, setNodes] = React.useState<Nodes>();
   const [clusterNodes, setClusterNodes] = React.useState<ClusterNode[] | undefined>();
   const [configurationNodeReponse, setConfigurationNodeReponse] = React.useState<ConfigurationNodeReponse | undefined>(undefined);
   
   const getNodes = ()=>{
      props.hub.invoke("SendGetNodesRequest", { producerName: "admin_ui", producerId: props.hub.connectionId });
   
   }
   props.hub.on("ReceiveGetNodesResponse", (nodes: Nodes) => {
      setNodes(nodes);
   });
   const loadPageData = (nodeName: string = "default") => {
      var node = (nodeName == "default")?nodes?.nodesInfo[0].name:nodeName;
      props.hub.invoke("SendGetNodeConfigurationRequest", { nodeName : node, producerName: "admin_ui", producerId: props.hub.connectionId })
      //invoke call for cluster values
   }
   props.hub.on("ReceiveGetNodeConfigurationResponse", (response:ConfigurationNodeReponse) => {
       setConfigurationNodeReponse(response);
   });
   const loadClusterData = () => {
      props.hub.invoke("SendGetClusterStatusRequest", { producerName: "admin_ui", producerId: props.hub.connectionId })
   }
   props.hub.on("ReceiveGetClusterStatusResponse", (response:any) => {
      setClusterNodes(response.nodes);
   });
    
  useEffect(() => {
     if(!nodes)
       getNodes();
  
      if(nodes){
         loadPageData();
         loadClusterData();
      }
   },[nodes]);

   return (<div className="App">
      <Nav hub={props.hub}></Nav>
      {(configurationNodeReponse == undefined)
         ? <Spinner /> :
         <div className="container">
            <div className="row">
               <div className="col">
                  <LeftMenu loadPage = {loadPageData} items={nodes?.nodesInfo.map((node: NodeInfo) => {return {nodeId: node.nodeId, name:node.name}})} />
               </div>
               <div className="col-12 col-sm-8">
                  <LeftMenuTabWrapper>
                   
                      <ClusterInfo loadClusterData={loadClusterData} nodes={(clusterNodes)? clusterNodes: undefined} />
                   
                      <LeftMenuTab key={configurationNodeReponse.nodeConfig.nodeId} nodeId={configurationNodeReponse.nodeConfig.nodeId} name={configurationNodeReponse.nodeConfig.nodeName} active={true}>
                           <div key={configurationNodeReponse.nodeConfig.nodeId} className="row mb-3 mt-3">
                              {configurationNodeReponse?.nodeConfig.sensorsConfiguration.map((sensorConfig:SensorConfiguration, index:number) =>{
                                 switch(sensorConfig.sensorTypeViewDTO.name){
                                     case "TH" : return <THSensorCard key={index +"_TH"} nodeName={configurationNodeReponse.nodeConfig.nodeName} config={sensorConfig} hub={props.hub} /> 
                                    // case "CAM" : return <CAMSensorCard key={index + "_CAM"} nodeName={nodeConfig.nodeName} config={sensorConfig} hub={props.hub}/>   
                                     case "ONOFF" : return <OnOffSensorCard key={index + "_ONOFF"} nodeName={configurationNodeReponse.nodeConfig.nodeName} config={sensorConfig} hub={props.hub}/>   
                                    }
                              })
                                                      
                              }
                           </div>
                     </LeftMenuTab>
                   
                  </LeftMenuTabWrapper>
               </div>
               <div className="col"></div>
            </div>
         </div>
      }
   </div>
   )
}
export default HomePage;