import React from 'react';
import { Link } from 'react-router-dom';
import { accountService } from '../Services/AccountService';

const EmailChangePasswordPage = () => {

  const [isDone, setIsDone] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isChanging, setIsChanging] = React.useState(false);

  let params = new URLSearchParams(window.location.search);
  let userid = params.get('userid');
  let token = params.get('token');

  const ResetPassword = (e: any) => {
    e.preventDefault();
    setIsChanging(true);
    accountService.reset_password({
      newPassword: e.target.newPassword.value,
      confirmNewPassword: e.target.confirmNewPassword.value,
      userId: (userid) ? userid : "",
      token: token ? token : ""
    })
      .then((res) => {
        setIsChanging(false);
        if (res.ok) {
          setIsDone(true);
          setIsError(false);
        } else
          setIsError(true);
        console.log(res);
      }).catch((e) => {
        console.log(e);
        setIsChanging(false);
        setIsError(true);
      });
  }
  return (
    <div className="App">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="mt-4 p-4">
              <form id='2' onSubmit={(e) => ResetPassword(e)} className="row g-3">
                <h4>Change Password</h4>
                <div className="col-12">
                  <label >Password</label>
                  <input type="password" name="newPassword" className="form-control" placeholder="Password" />
                </div>
                <div className="col-12">
                  <label>Confirm Password</label>
                  <input type="password" name="confirmNewPassword" className="form-control" placeholder="Confirm Password" />
                </div>
                <div className="col-12">
                  {(!isChanging) ?
                    <div className="d-grid gap-2">
                      <button type="submit" className="btn btn-primary">Change</button>
                    </div>
                    :
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Changing...
                      </button>
                    </div>
                  }
                </div>
              </form>
              <hr className="mt-4" />
              <div className="col-12">
                {isDone &&
                  <div className="alert alert-success" role="alert">Your password is been reset! <Link className="alert-link" to={"/login"}>Login</Link></div>
                }
                {isError &&
                  <div className="alert alert-danger" role="alert">Error: Try later or contact the Admin.</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailChangePasswordPage;