import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface AuthRouteProps  {
  component: any;  
  path: string;
  exact: boolean;
  hub?:any;
}
const getauth = ()=>{
    let auth = localStorage.getItem('auth');
    if(auth)
     return auth;
    return null
   }

const AuthRoute : React.FC<AuthRouteProps> = ({ component: Component , hub, ...rest }) => {

const auth = getauth();    
const redirect = auth ? false : true; 
const redirectTo = redirect ? window.location.pathname : "";

return (
   <Route render={props =>
        redirect ? <Redirect to={`/login?redirectTo=${redirectTo}`}/>
        :
         <Component {...props} hub={hub}/> 
    } {...rest}/>
   )
}

export {AuthRoute};
